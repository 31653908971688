.terms-conditions {
    display: flex;
    padding: 0 3rem 0 3rem;
}

.terms-conditions-intro {
    display: flex;
    position: relative;
    flex: 1;
    margin-top: 5rem;
    flex-direction: column;
    margin-left: 24px;
    margin-right: 24px;
}

.terms-conditions .terms-conditions-intro h1 {
    color: #004E42;
    margin-top: 70px;
    text-align: center !important;
    align-items: center;
    margin-bottom: 10px;
}

.terms-conditions .terms-conditions-intro hr {
    width: 8%;
    height: 2px;
    margin: 0 auto;
    background-color: #004E42;
    border: none;
}

.terms-conditions-par {
    margin-top: 50px;
}

.terms-conditions .terms-conditions-intro p {
    margin-top: 30px;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    text-align: justify;
}

.terms-conditions .terms-conditions-intro h3 {
    margin-top: 30px;
    color: #333333;
}

.terms-conditions .terms-conditions-intro ul {
    list-style: none;
    color: #333333;
    margin-left: 15px;
    margin-top: 30px;
}

.terms-conditions .terms-conditions-intro ul li {
    margin-top: 10px;
    line-height: 22px;
}

@media only screen and (max-width: 767px) {
    .terms-conditions {
        display: flex;
        height: 100%;
        padding: 0;
    }

    .terms-conditions-par {
        margin-top: 10px;
    }
}
