/* Home Save Money start */

.home {
    display: flex;
    height: 120vh;
    padding: 0 3rem 0 3rem;
}

.hero-intro {
    display: flex;
    position: relative;
    flex: 1;
    margin-top: 5rem;
    flex-direction: column;
}

.home .hero-intro h1 {
    font-size: 64px;
    color: #DDAB29;
    margin-top: 80px;
    text-align: center;
}

.home .hero-intro h1 .ten-number-text {
    font-size: 80px !important;
}

.save-money-black-text {
    color: #000;
    font-weight: 400;
}

.save-money {
    color: #004E42;
    font-weight: 400;
}

.automatic {
    color: #004E42;
    display: flex;
    justify-content: center;    
}

.home .hero-intro p {
    margin-top: 5px;
    padding: 0;
    font-size: 24px;
    font-weight: lighter;
    text-align: center;
}

.hero-intro-imgs {
    margin-top: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem !important;
}

.hero-intro-imgs img {
    max-width: 100%;
    height: auto;
}

.animated {
    animation: up-down 2s ease-in-out infinite alternate both ;
}


@-webkit-keyframes up-down {
    0% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(-10px);
    }
}

@keyframes up-down {
    0% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(-10px);
    }
    
}

.blur {
    position: absolute;
    width: 26rem;
    height: 20rem;
    border-radius: 50%;
    background: #FCE27C;
    z-index: -9;
    top: -15%;
    /* left: 70%; */
    right: 0px;
    filter: blur(110px);
}

/* Top Home Save Money end */


/* digital-gold start */

.digital-gold {
    border: 3px solid #FDE174;
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 20px 4vw;
    margin-top: 150px;
    margin-left: 60px !important;
    margin-right: 60px !important;
    border-radius: 40px;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 50px;
}

.digital-gold .main-text {
    text-align: center;
    width: 70%;
}

.main-text .digital-boxs {
    margin-top: -37px;
}

.main-text .digital-boxs h6 {
    font-size: 16px;
    color: #004E42 !important;
    margin-bottom: 30px;
    background: #FFD640;
    width: 240px;
    height: 30px;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
    margin: 0 auto;
}

.main-par {
    display: flex;
    margin: 0 auto;
}

.main-text .main-par p {
    font-size: 24px;
    color: #333333;
    margin-bottom: 40px;
    margin-top: 40px;
}

.blur-left {
    position: absolute;
    width: 26rem;
    height: 20rem;
    border-radius: 50%;
    background: #FCE27C;
    z-index: -9;
    top: 110%;
    /* right: 70%; */
    left: 0px;
    filter: blur(110px);
}

/* digital-gold end */


/* How it works start */

.how-it-work {
    color: #333333;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding-top: 80px;
    margin-top: 50px;
}

.work-content {
    width: 100%;
    display: grid;
    grid-template-columns:  1fr 1fr;
    gap: 80px;
}

.work-left {
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 20px;
    cursor: pointer;
    margin-left: 60px;
    margin-top: 50px;
}

.how-it-work p {
    text-align: center;
    color: #DDAB29;
    font-weight: 600;
    font-size: 20px;
}

.how-it-work h1 {
    text-align: center;
    color: #DDAB29;
}

.css-1086bdv-MuiPaper-root-MuiAccordion-root {
    border: 2px solid #FDE174;
    border-radius: 20px !important;
    padding: 20px !important;
    font-size: 22px !important;
    font-weight: 600 !important;
    color: #333333 !important;
    position: inherit !important;
}

.learn-boxs {
    background-color: #FFF3C2;
    border-radius: 30px !important;
    margin-left: 18px;
    padding: 0px !important;
    margin-bottom: 15px;
    margin-top: 15px;
}

#learn_more {
    color: #EBD38C !important;
    background-color: #333333 !important;
    padding: 8px 22px !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
    border-radius: 30px !important;
    font-size: 15px !important;
}

.work-left .watch-video {
    margin-left: 20px;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    align-items: center;
    margin-right: 15px;
}

.work-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 80px;
    margin-right: 60px;
}

.work-right img {
    max-width: 100%;
    height: auto;
}

.s-blur2 {
    position: absolute;
    width: 26rem;
    height: 20rem;
    border-radius: 50%;
    background: #FCE27C;
    z-index: -9;
    /* left: 58rem; */
    right: 0px;
    top: 88rem;
    filter: blur(110px);
}

/* How it works end */


/* hot-eg-gold start */

.hot-eg-gold {   
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    margin-bottom: 100px;   
}

.hot-eg-gold h1 {
    margin-top: 50px;
    text-align: center;
    margin-bottom: 50px;
    color: #004E42;
}

.hot-reward {
    border: 3px solid #FDE174;
    padding: 20px 6.9vw;
    border-radius: 40px;
    position: relative;
    background-color: #FEF7DD;
}

.hot-eg-gold-content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 60px;
    margin-top: 30px;
    margin-bottom: 30px;
}


/* .hot-eg-gold-left, .hot-eg-gold-center, .hot-eg-gold-right  {
    border: 3px solid #FDE174;
    padding: 15px;
    border-radius: 40px;
    position: relative;
} */

.blur-hot {
    position: absolute;
    width: 26rem;
    height: 20rem;
    border-radius: 50%;
    background: #FCE27C;
    z-index: -9;
    top: 420%;
    /* right: 70%; */
    left: 0px;
    filter: blur(110px);
}

/* hot-eg-gold end */


/*  saving-eg-gold start */

.saving-eg-gold {
    color: #333333;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px 4.4vw;
    margin-top: 50px;
}

.saving-eg-gold h1 {
    margin-top: 50px;
    color: #004E42;
    text-align: center;
}

.saving-eg-gold-content {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    margin-top: 50px;
}

.css-1633um2-MuiPaper-root {
    background-color: transparent !important;
    border-radius: 0px;
    box-shadow: none !important;
}

.saving-eg-gold-left, .saving-eg-gold-center, .saving-eg-gold-right {
    background: #fff;
    border: 3px solid #FDE174;
    display: flex;
    flex-direction: column;
    gap: 20px;
    cursor: pointer;
    padding: 20px 4vw;
    border-radius: 20px;
    color: #333333;
    margin-top: 60px;
    align-items: center;
}

.saving-eg-gold-left .automated-icon, .saving-eg-gold-center .many-save-icon, .saving-eg-gold-right .withdraw-icon {
    margin-top: -113px;
}

.saving-eg-gold-left img, .saving-eg-gold-center img, .saving-eg-gold-right img {
    margin-top: 40px;
}

.saving-eg-gold-left h3, .saving-eg-gold-center h3, .saving-eg-gold-right h3 {
    margin-top: 20px;
}

.saving-eg-gold-left p, .saving-eg-gold-center p, .saving-eg-gold-right p {
    text-align: center;
    margin-bottom: 10px;
}

/*  saving-eg-gold end */


/* partners start */

.partners {
    color: #333333;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px 4vw;
    margin-top: 40px;
    margin-left: 50px;
    margin-right: 50px;
}

.partners h1 {
    text-align: center;
    color: #004E42;
}

.partners-content {
    /* width: 100%; */
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 80px;
    margin-top: 50px;
}

/* partners end */


/* calculate start */

.calculate {
    color: #333333;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px 4vw;
    margin-top: 40px;
}

.calculate-content {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
    margin-top: 50px;
}

.calculate h1 {
    text-align: left;
    margin-bottom: 40px;
    line-height: 80px;
    color: #333333;
    font-size: 64px;
    margin-top: 100px;
}

.calculate-now {
    background: #004E42;
    padding: 10px 20px;
    color: #EBD38C;
    border-radius: 8px;
    border: none;
    outline: none;
    font-size: 20px;
}

.container {
    max-width: 400px;
    margin: 20px auto;
    padding: 20px;
    border-radius: 20px;
    background-color: #EEDA76;
}
  
.tabs {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 50px !important;
}
  
.tabs button {
    flex: 1;
    padding: 10px;
    border: none;
    background: #fff;
    color: #004E42;
    font-size: 18px;
    cursor: pointer;
    border-radius: 50px !important;
}
  
.tabs button.active {
    background: #004E42;
    color: #fff;
}

.golds-amounts {
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
}
  
h2 {
    margin-bottom: 20px;
    text-align: left;
    color: #004E42;
}
  
.input-container {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    background-color: #EDEDED;
    border-radius: 12px;
}
 
.input-container button {
    padding: 10px;
    margin-bottom: 10px;
    background: #EDEDED;
    cursor: pointer;
    flex: 1;
    border-radius: 8px;
    margin: 6px;
    border: none;
    color: #333333;
    font-size: 18px;
}
  
.input-container button.active {
    background: #333333;
    color: #fff;
}

.input-container1 {
    display: flex;
    flex-direction: column;
    margin-bottom: 0px;
}
  
.input-box {
    display: flex;
    align-items: center;
    border: 1px solid #333333;
    padding: 0px;
    border-radius: 10px;
    font-size: 16px;
    margin-bottom: 5px;
}

.buy-gst {
    font-size: 10px;
    margin-bottom: 20px;
    text-align: right;
    color: #7E7E7E;
}
  
.input-box input {
    flex: 1;
    width: 80%;
    height: 40px;
    border-radius: 10px;
    padding: 0px;
    border: none;
    margin: 0px auto;
    padding-left: 5px;
    font-size: 18px;
}

.input-box input:focus {
    outline: 0px solid white; /* Set the outline to white on focus */
    border: 0px solid white; /* Set the border to white on focus */
}
  
.input-box span {
    margin-right: 10px;
    font-size: 14px;
    color: #7E7E7E;
    width: auto;
    text-align: center;
    font-weight: 600;
}

.rupees-icons {
    font-size: 18px !important;
    color: #000 !important;
    margin-left: 10px !important;
}
  
.live-price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #EDEDED;
    border-radius: 20px;
    margin-bottom: 15px;
    color: #333333;
    height: 40px;
    font-weight: 600;
    padding-right: 10px;
}
  
.live-indicator {
    color: red;
    font-size: 14px;
    background: #C9C9C9;
    border-radius: 20px;
    padding: 11px 16px;
    font-weight: 600;
}

.blink {
    font-size: 16px;
}

.blink {
    animation: blink-animation 1s steps(5, start) infinite;
    -webkit-animation: blink-animation 1s steps(5, start) infinite;
}

@keyframes blink-animation {
    to {
      visibility: hidden;
    }
}
@-webkit-keyframes blink-animation {
    to {
      visibility: hidden;
    }
}
  
.slider-container {
    height: 6px;
    background: #D9D9D9;
    border-radius: 5px;
    margin-bottom: 20px;
    overflow: hidden;
    position: relative;
}
  
.slider-bar {
    height: 100%;
    background: #004E42;
    transition: width 1s linear;
}
  
.buy-button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 11px;
    background: #004E42;
    color: white;
    font-size: 18px;
    cursor: pointer;
}

.buy-button1 {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 11px;
    background: #CB991D;
    color: white;
    font-size: 18px;
    cursor: pointer;
}

.buy-button1:hover {
    background: #004E42;
}
  
.buy-button:hover {
    background: #CB991D;
}

.blur-calculate {
    position: absolute;
    width: 26rem;
    height: 20rem;
    border-radius: 50%;
    background: #FCE27C;
    z-index: -9;
    top: 672%;
    /* left: 68%; */
    right: 0px;
    filter: blur(110px);
}

/* calculate end */


/* Save every day, withdraw any day start */

.withdraw-save {
    background: transparent;
    color: #333333;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    cursor: pointer;
    padding: 20px 4vw;
    margin-top: 30px;
}

.withdraw-save h1 {
    margin-top: 30px;
    text-align: center;
    color: #004E42;
}

.withdraw-save-left {
    border: 1px solid #000;
    display: flex;
    align-items: center;
    gap: 20px;
    cursor: pointer;
    padding: 20px 4vw;
    border-radius: 40px;
}

.withdraw-save-left p {
    font-size: 26px;
    font-weight: 600;
    margin-left: 30px;
}

.withdraw-save-right {
    border: 1px solid #000;
    display: flex;
    align-items: center;
    gap: 20px;
    cursor: pointer;
    padding: 20px 4vw;
    border-radius: 40px;
}

.withdraw-save-right p {
    font-size: 26px;
    font-weight: 600;
    margin-right: 55px;
}

.withdraw-save-content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
}

/* .withdraw-save-left, .withdraw-save-right {
    display: flex;
} */

.withdraw-save-center {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #CB991D;
    font-size: 22px;
    margin-left: 35px;
    margin-right: -0px;
    position: absolute;
    color: #fff;
}

.withdraw-save-center p {
    margin: 0;
}

.learn-more {
    border: 1px solid #004E42;
    background: transparent;
    color: #004E42;
    padding: 12px 26px;
    border-radius: 10px;
    font-size: 18px;
    margin-bottom: 20px;
    cursor: pointer;
}

.blur-withdraw {
    position: absolute;
    width: 26rem;
    height: 20rem;
    border-radius: 50%;
    background: #FCE27C;
    z-index: -9;
    top: 785%;
    /* right: 75%; */
    left: 0px;
    filter: blur(110px);
}

/* Save every day, withdraw any day end */

/* Meet the people start */

.meet-people {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px 4vw;
    margin-top: 40px;
}

.meet-people h1 {
    text-align: center;
    color: #004E42;
}

.carousel {
    padding: 3rem 5rem !important;
    color: #fff;
    height: 60vh;
    width: 95vw;
}

.box img {
    height: auto;
}

.box h3 {
    margin-top: 10px;
    color: #333333 !important;
}

.box p {
    margin-top: 5px;
    color: #333333 !important;
}

.slick-slide div {
    margin: 1rem;
}

.slick-prev::before {
    color: #004E42 !important;
    display: none;
}

.slick-next::before {
    color: #004E42 !important;
    display: none;
}

.slick-dots li.slick-active button:before {
    color: #EABE0B !important;
}

.slick-dots li button::before {
    color: #004E42 !important;
    font-size: 1rem !important;
    top: 2rem !important;
}

/* Meet the people end */


/* EG in News start */

.eg-news {
    color: #333333;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px 4vw;
    margin-top: 70px;
    margin-left: 50px;
    margin-right: 50px;
    height: 80vh;
}

.user-testimonial    {
    text-align: center;
    color: #DDAB29;
    font-weight: 600;
    font-size: 20px;
}

.eg-news h1 {
    text-align: center;
    color: #004E42;
    margin-top: 0px;
}

.carousel {
    padding: 2rem 5rem !important;
    color: #fff !important;
    height: 60vh !important;
    width: 98vw !important;
}

.boxs {
    border: 1px solid #000;
    height: 270px;
    border-radius: 20px;
    padding: 20px;
}

.boxs img {
    margin-top: 40px;
}

.boxs h4 {
    color: #333333;
    margin-top: 18px;
}

.boxs p {
    margin-top: 20px;
    color: #333333;
}

.slick-slick div {
    margin: 0 1rem !important;
}


/* EG in News end */

/* Our control of knowledge start */

.control-knowledge {
    color: #333333;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    cursor: pointer;
    padding: 20px 4vw;
    height: 100% !important;
}

.control-knowledge p {
    text-align: center;
    color: #004E42;
    font-weight: 600;
    margin-top: 30px;
}

.control-knowledge h1 {
    text-align: center;
    color: #004E42;
}

.control-knowledge-content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 40px;
    margin-top: 50px;
}

.control-knowledge-left h4, .control-knowledge-center h4, .control-knowledge-right h4 {
    padding: 20px;
    color: #333333;
    font-size: 16px;
}

.control-knowledge-left p, .control-knowledge-center p, .control-knowledge-right p {
    text-align: left;
    margin-left: 20px;
    margin-top: -5px;
    padding-bottom: 20px;
    color: #333333;
    font-weight: 400;
    font-size: 14px;
}

.view-blogs {
    border: 1px solid #004E42;
    background: transparent;
    color: #004E42;
    padding: 12px 26px;
    border-radius: 10px;
    font-size: 18px;
    margin-bottom: 20px;
    margin-top: 40px;
}

/* Our control of knowledge end */

/* All your questions, answered start */

.questions-answered {
    color: #333333;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    cursor: pointer;
    padding: 20px 4vw;
    margin-top: 80px;
}

.questions-answered h1 {
    text-align: center;
    color: #004E42;
}

.questions-answered-content {
    display: grid;
    grid-template-columns: 1fr;
    gap: 40px;
    margin-top: 50px;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #fff !important;
    background-color: #004E42 !important;
    border-radius: 40px !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
    margin: 10px !important;
    padding: 0px 30px !important;
} 

.css-1gsv261 {
    border-bottom: 0px solid !important;
    border-bottom-color: currentcolor;
    border-color: rgba(0, 0, 0, 0.12);
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    color: #333333 !important;
    font-size: 18px !important;
    border: 1px solid #004E42 !important;
    border-radius: 40px !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
    margin: 10px !important;
    padding: 0px 30px !important;
}

.css-1aquho2-MuiTabs-indicator {
    position: absolute;
    height: 0px !important;
    bottom: 0;
    width: 100%;
    -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #1976d2;
}

.css-1hew1gp-MuiPaper-root-MuiAccordion-root::before {
    position: absolute;
    left: 0;
    top: -1px;
    right: 0;
    height: 0px !important;
    content: "";
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.12);
    -webkit-transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

.css-heg063-MuiTabs-flexContainer {
    display: flex;
    justify-content: center !important;
}

.faq-ans {
    border-radius: 18px !important;
    padding: 15px;
    margin-top: 20px !important;
}

.load-more-button {
    justify-content: center;
    align-items: center;
    text-align: center;
}

.load-more {
    border: 1px solid #004E42;
    background: transparent;
    color: #004E42;
    padding: 12px 26px;
    border-radius: 10px;
    font-size: 18px;
    margin-top: 60px;
    cursor: pointer;
}

.css-1hew1gp-MuiPaper-root-MuiAccordion-root {
    background-color: transparent !important;
    border: 1px solid #000 !important;
}


@media only screen and (max-width: 500px) {
/* Home Save Money start */

    .home .hero-intro h1 {
        font-size: 30px !important;
        color: #DDAB29;
        margin-top: 80px;
        text-align: center;
    }

    .home .hero-intro h1 img {
        width: 60px !important;
    }

    .home .hero-intro h1 .ten-number-text {
        font-size: 40px;
    }

    .home .hero-intro p {
        margin-top: 5px;
        padding: 0;
        font-size: 20px;
        font-weight: 400;
        text-align: center;
    }

    /* .blur {
        position: absolute;
        width: 16rem;
        height: 10rem;
        border-radius: 50%;
        background: #FCE27C;
        z-index: -9;
        top: -10%;
        left: 20%;
        filter: blur(72px);
    } */

    /* digital-gold start */

    .digital-gold {
        margin-top: 10px !important;
        margin-left: 24px !important;
        margin-right: 24px !important;
    }

    .digital-gold .main-text {
        text-align: center;
        width: 100%;
    }

    .main-text .main-par p {
        padding: 0px !important;
    }

    /* saving-eg-gold start */

    .saving-eg-gold {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 4vw;
        padding-top: 20px;
        margin-top: 160px !important;
        height: 130vh;
    }

    .saving-eg-gold h1 {
        margin-top: 50px;
        color: #004E42;
        text-align: center !important;
    }

    .css-heg063-MuiTabs-flexContainer {
        overflow-x: scroll !important;
        display: block !important;
        padding-left: 20px !important;
        padding-bottom: 5px !important;
        width: 90% !important;
        margin-left: 24px !important;
        margin-right: 24px !important;
    }

    .hot-eg-gold {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
        margin-left: 24px;
        margin-right: 24px;
        height: 160vh !important;
    }
      
}


/*  Mobile View Top Home Save Mone */

@media only screen and (max-width: 767px) {

/* Home Save Money start */

    .home {
        height: 120vh;  
    }

    .hero-intro {
        display: flex;
        position: relative;
        margin-top: 6rem;
        flex-direction: column;
    }

    .home .hero-intro h1 {
        font-size: 40px;
        color: #DDAB29;
        margin-top: 80px;
        text-align: center;
    }

    .home .hero-intro h1 .ten-number-text {
        font-size: 40px !important;
    }

    .home .hero-left p {
        margin-top: 10px;
        padding: 0;
        font-size: 20px;
    }

    .hero-intro-imgs {
        margin-top: 3rem;
        justify-content: center;
    }

    .hero-intro-imgs img {
        width: 300px;
        height: auto;
    }
    
    .blur {
        position: absolute;
        width: 18rem;
        height: 10rem;
        border-radius: 50%;
        background: #FCE27C;
        z-index: -9;
        top: -12%;
        /* left: 50%; */
        right: 0px;
        filter: blur(90px);
    }
        
/* digital-gold start */

    .digital-gold {
        margin-left: 24px;
        margin-right: 24px;
        margin-top: 50px;
    }
  
    .main-par {
        display: flex;
        max-width: 100%;
        margin: 0 auto;
    }

    .main-text .main-par p {
        font-size: 20px;
        color: #333333;
        margin-bottom: 20px;
        padding: 10px;
        text-align: left;
    }

    .digital-gold .main-text {
        text-align: center;
        width: 100%;
    }

    .blur-left {
        position: absolute;
        width: 18rem;
        height: 10rem;
        border-radius: 50%;
        background: #FCE27C;
        z-index: -9;
        top: 105%;
        /* right: 50%; */
        left: 0px;
        filter: blur(90px);
    }

    /* .blur-left {
        display: none;
    } */


/* How it works */

    .how-it-work {
        color: #333333;
        padding-top: 40px;
    }

    .work-content {
        display: flex;
        flex-direction: column;
        gap: 35px;
    }

    .work-left {
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 20px;
        cursor: pointer;
        margin-left: 24px;
        margin-top: 20px;
        margin-right: 24px;
    }

    .css-1086bdv-MuiPaper-root-MuiAccordion-root {
        border: 3px solid #FDE174;
        border-radius: 20px !important;
        padding: 5px !important;
        font-size: 18px !important;
        font-weight: 600 !important;
        color: #333333 !important;
    } 

    #learn_more {
        color: #EBD38C !important;
        background-color: #333333 !important;
        padding: 6px 16px !important;
        font-weight: 600 !important;
        text-transform: capitalize !important;
        border-radius: 20px !important;
        font-size: 14px !important;
    }
    
    .work-left .watch-video {
        margin-left: 20px;
        font-size: 16px;
        font-weight: 400;
    }
     
    .work-right {
        margin-left: 24px !important;
        margin-right: 24px !important;
    }

    .work-right img {
        width: 300px;
        height: auto;
    }

    .s-blur2 {
        position: absolute;
        width: 18rem;
        height: 10rem;
        border-radius: 50%;
        background: #FCE27C;
        z-index: -9;
        /* left: 15rem; */
        right: 0px;
        top: 290%;
        filter: blur(90px);
    }

    /* .s-blur2 {
        display: none;
    } */

    /* hot-eg-gold */

    .hot-eg-gold {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
        margin-left: 24px;
        margin-right: 24px;
        height: 120vh;
    }

    .hot-eg-gold-content{
        display: flex;
        flex-direction: column;
        gap: 35px;
    }
    
    .blur-hot {
        position: absolute;
        width: 18rem;
        height: 15rem;
        border-radius: 50%;
        background: #FCE27C;
        z-index: -9;
        top: 455%;
        /* right: 60%; */
        left: 0px;
        filter: blur(110px);
    }

    /* .blur-hot {
        display: none;
    } */

    /*  saving-eg-gold */

    .saving-eg-gold {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 4vw;
        padding-top: 20px;
        margin-top: 160px;
        height: 130vh;
    }

    .saving-eg-gold-content{
        display: flex;
        flex-direction: column;
        gap: 80px;
    }

    .saving-eg-gold-left, .saving-eg-gold-center, .saving-eg-gold-right {
        background: #fff;
        border: 3px solid #FDE174;
        display: flex !important;
        flex-direction: column !important;
        gap: 40px !important;
        cursor: pointer;
        padding: 20px 4vw !important;
        border-radius: 20px !important;
        color: #333333;
        margin-top: 60px !important;
        align-items: center !important;
    }

    .slick-slide img {
        display: initial !important;
    }
    
    .saving-eg-gold-left .automated-icon, .saving-eg-gold-center .many-save-icon, .saving-eg-gold-right .withdraw-icon {
        margin-top: -20px !important;
        text-align: center !important;
    }
    
    .saving-eg-gold-left img, .saving-eg-gold-center img, .saving-eg-gold-right img {
        margin-top: 10px !important;
        text-align: center !important;
    }
    
    .saving-eg-gold-left h3, .saving-eg-gold-center h3, .saving-eg-gold-right h3 {
        margin-top: 0px !important;
        text-align: center !important;
    }
    
    .saving-eg-gold-left p, .saving-eg-gold-center p, .saving-eg-gold-right p {
        text-align: center;
        margin-bottom: 10px;
    }

    /* partners */

    .partners {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 4vw;
        padding-top: 0px;
        margin-top: 100px;
        margin-left: 24px;
        margin-right: 24px;
    }

    .partners-content{
        display: flex;
        gap: 20px;
        align-items: center;
        margin-top: 30px !important;
    }
    
    .partners-imgs {
        width: 110px !important;
    } 

    /* calculate start */

    .calculate {
        color: #333333;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        padding: 20px 4vw;
        margin-top: 40px;
    }

    .calculate-content {
        display: flex;
        flex-direction: column;
        gap: 80px;
        margin-top: 10px !important;
    }

    .calculate h1 {
        text-align: left;
        margin-bottom: 40px;
        line-height: 50px;
        color: #333333;
        font-size: 40px;
        margin-top: 0px;
    }

    .calculate-now {
        background: #004E42;
        padding: 10px 20px;
        color: #EBD38C;
        border-radius: 8px;
        border: none;
        outline: none;
        font-size: 20px;
    }

    .container {
        max-width: 100%;
        margin: 20px auto;
        padding: 20px;
        border-radius: 20px;
        background-color: #EEDA76;
    }
  
    .tabs {
        display: flex;
        justify-content: space-around;
        margin-bottom: 20px;
        background-color: #fff;
        border-radius: 50px !important;
    }
  
    .tabs button {
        flex: 1;
        padding: 10px;
        border: none;
        background: #fff;
        color: #004E42;
        font-size: 18px;
        cursor: pointer;
        border-radius: 50px !important;
    }
  
    .tabs button.active {
        background: #004E42;
        color: #fff;
    }

    .golds-amounts {
        background-color: #fff;
        padding: 20px;
        border-radius: 20px;
    }
  
    h2 {
        margin-bottom: 20px;
        text-align: left;
        color: #004E42;
    }
    
    .input-container {
        display: flex;
        justify-content: space-around;
        margin-bottom: 20px;
        background-color: #EDEDED;
        border-radius: 12px;
    }
    
    .input-container button {
        padding: 10px;
        margin-bottom: 10px;
        background: #EDEDED;
        cursor: pointer;
        flex: 1;
        border-radius: 8px;
        margin: 6px;
        border: none;
        color: #333333;
        font-size: 18px;
    }
    
    .input-container button.active {
        background: #333333;
        color: #fff;
    }

    .input-container1 {
        display: flex;
        flex-direction: column;
        margin-bottom: 0px;
    }
    
    .input-box {
        display: flex;
        align-items: center;
        border: 1px solid #333333;
        padding: 0px;
        border-radius: 10px;
        font-size: 16px;
        margin-bottom: 5px;
    }

    .buy-gst {
        font-size: 10px;
        margin-bottom: 20px;
        text-align: right;
        color: #7E7E7E;
    }
    
    .input-box input {
        flex: 1;
        width: 80%;
        height: 40px;
        border-radius: 10px;
        padding: 0px;
        border: none;
        margin: 0px auto;
        padding-left: 5px;
        font-size: 18px;
    }

    .input-box input:focus {
        outline: 0px solid white; /* Set the outline to white on focus */
        border: 0px solid white; /* Set the border to white on focus */
    }
    
    .input-box span {
        margin-right: 10px;
        font-size: 14px;
        color: #7E7E7E;
        width: auto;
        text-align: center;
        font-weight: 600;
    }

    .rupees-icons {
        font-size: 18px !important;
        color: #000 !important;
        margin-left: 10px !important;
    }
    
    .live-price {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #EDEDED;
        border-radius: 20px;
        margin-bottom: 15px;
        color: #333333;
        height: 40px;
        font-weight: 600;
        padding-right: 10px;
    }
  
    .live-indicator {
        color: red;
        font-size: 14px;
        background: #C9C9C9;
        border-radius: 20px;
        padding: 11px 16px;
        font-weight: 600;
    }
    
    .slider-container {
        height: 6px;
        background: #D9D9D9;
        border-radius: 5px;
        margin-bottom: 20px;
        overflow: hidden;
        position: relative;
    }
  
    .slider-bar {
        height: 100%;
        background: #004E42;
        transition: width 1s linear;
    }
    
    .buy-button {
        width: 100%;
        padding: 10px;
        border: none;
        border-radius: 11px;
        background: #004E42;
        color: white;
        font-size: 18px;
        cursor: pointer;
    }

    .buy-button1 {
        width: 100%;
        padding: 10px;
        border: none;
        border-radius: 11px;
        background: red;
        color: white;
        font-size: 18px;
        cursor: pointer;
    }
    
    .buy-button:hover {
        background: #CB991D;
    }

    .blur-calculate {
        position: absolute;
        width: 18rem;
        height: 10rem;
        border-radius: 50%;
        background: #FCE27C;
        z-index: -9;
        top: 775%;
        /* left: 50%; */
        right: 0px;
        filter: blur(90px);
    }

    /* .blur-calculate {
        display: none;
    } */

    /* calculate end */

    /* Save every day, withdraw any day start */

    .withdraw-save h1 {
        margin-top: 0px;
        text-align: center;
        color: #004E42;
    }

    .withdraw-save-content {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .withdraw-save-left {
        background: #FFF3C2;
        display: block;
        cursor: pointer;
        padding: 20px 4vw;
        border-radius: 20px;
        text-align: center;
    }

    .withdraw-save-left img {
        max-width: 100px;
    }

    .withdraw-save-left p {
        font-size: 18px;
        font-weight: 600;
        margin-left: 0px;
        margin-top: 10px;
    }

    .withdraw-save-right {
        background: #FFF3C2;
        display: block;
        cursor: pointer;
        padding: 20px 4vw;
        border-radius: 20px;
        text-align: center;
    }

    .withdraw-save-right img {
        max-width: 100px;
    }

    .withdraw-save-right p {
        font-size: 18px;
        font-weight: 600;
        margin-right: 0px;
        margin-top: 10px;
    }

    .withdraw-save-center {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: #FCE27C;
        font-size: 18px;
        margin-left: 15px;
        margin-right: -22px;
        position: absolute;
        color: #333333;
    }

    .blur-withdraw {
        position: absolute;
        width: 18rem;
        height: 12rem;
        border-radius: 50%;
        background: #FCE27C;
        z-index: -9;
        top: 910%;
        /* right: 75%; */
        left: 0px;
        filter: blur(110px);
    }

    /* .blur-withdraw {
        display: none;
    } */
    

    /* Meet the people start */
    .meet-people {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        padding: 20px 4vw;
        margin-top: 40px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .carousel {
        padding: 0rem !important;
        color: #fff;
        height: 55vh;
        width: 100vw;
        margin-left: 24px;
        margin-right: 24px;
    }


    /* EG in News start */
    .eg-news {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        padding: 20px 4vw;
        margin-top: 100px !important;
        margin-left: 20px;
        margin-right: 20px;
    }

    .carousel {
        padding: 2rem 3rem !important;
        color: #fff !important;
        height: 55vh !important;
        width: 100vw !important;
    }

    .boxs {
        background: transparent;
        height: 250px;
        border-radius: 20px;
        padding: 30px;
    }

    .boxs img {
        margin-top: 30px;
    }

    /* Our control of knowledge start */
    
    .control-knowledge {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 0px;
        padding-bottom: 60px;
        padding-top: 0px;
    }

    .view-blogs {
        border: 1px solid #004E42;
        background: transparent;
        color: #004E42;
        padding: 12px 26px;
        border-radius: 10px;
        font-size: 18px;
        margin-bottom: 20px;
        margin-top: 160px !important;
    }

    .slick-slide div {
        margin: 0 0.5rem !important;
    }

    /* All your questions, answered start */

    .questions-answered h1 {
        text-align: center;
        color: #004E42;
        margin-top: 120px;
    }

    .css-1wcaknn {
        padding: 0px !important;
        margin-left: 24px !important;
        margin-right: 24px !important;
    }

    .load-more {
        margin-bottom: -40px;
    }

    .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
        color: #333333 !important;
        background-color: #FCE27C !important;
        border-radius: 40px !important;
        font-size: 12px !important;
        font-weight: 600 !important;
        text-transform: capitalize !important;
        margin: 5px !important;
        padding: 2px 6px !important;
        min-height: 10px !important;
    }

    .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
        color: #333333 !important;
        font-size: 18px !important;
        background-color: #FFF3C2 !important;
        border-radius: 40px !important;
        font-size: 12px !important;
        font-weight: 600 !important;
        text-transform: capitalize !important;
        margin: 5px !important;
        padding: 2px 6px !important;
        min-height: 10px !important;
    }

    .css-heg063-MuiTabs-flexContainer {
        overflow-x: scroll !important;
        display: block !important;
        padding-left: 20px !important;
        padding-bottom: 5px !important;
        width: 90% !important;
    }

    .explore-menu-list {
        overflow-x: auto; /* Enable horizontal scrolling */
        white-space: nowrap; /* Prevent tabs from wrapping */
    }
      
    .MuiTabs-root {
        width: 110%;
    }
      
    .MuiTab-root {
        min-width: auto !important; /* Allow tabs to shrink */
        flex-grow: 1; /* Make tabs take equal space */
        text-align: center;
    }
}


@media only screen and (max-width: 991px) {

    .boxs {
        border: 1px solid #000;
        height: 315px;
        padding: 20px;
    }
}

