.footer {
    color: #333333;
    background: linear-gradient(120deg, rgba(252,226,124,1) 0%, rgba(234,190,11,1) 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px 4vw;
    padding-top: 80px;
    margin-top: 50px;
}

.footer-content {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 80px;
}

.privacy-policy {
    display: flex;
    gap: 20px;
    list-style: none;
}

.footer-content-left, .footer-content-right, .footer-content-center {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 20px;
}

.footer-content-right li, .footer-content-center li {
    list-style: none;
    margin-bottom: 20px;
    cursor: pointer;
}

.footer-content-right h2, .footer-content-center h2 {
    color: #333333;
}

.footer-social-icons {
    text-align: center;
    padding: 10px 0;
    cursor: pointer;
}

.social-icons {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
}

.social-icons li {
    margin: 0 6px;
}

.social-icons-media {
    font-size: 18px;
    color: #FCE27C;
    background-color: #004E42;
    padding: 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.social-icons-media:hover {
    color: #FCE27C;
}

.footer hr {
    width: 100%;
    height: 1px;
    margin: 20px 0px;
    background-color: #333333;
    border: none;
}

@media (max-width:767px) {
    .footer-content {
        display: flex;
        flex-direction: column;
        gap: 35px;
    }

    .footer-address {
        display: flex;
        flex-direction: column;
        gap: 35px; 
    }

    .footer-content-right-items {
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 20px;
    }

    .privacy-policy {
        display: grid;
        gap: 20px;
        list-style: none;
    }

    .footer-copyright {
        text-align: center;
    }
}
