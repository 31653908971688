.about {
    display: flex;
    height: 100%;
    padding: 0 60px 0 60px;
}

.about-intro {
    display: flex;
    position: relative;
    flex: 1;
    margin-top: 5rem;
    flex-direction: column;
}

.about .about-intro h1 {
    color: #004E42;
    margin-top: 70px;
    text-align: center !important;
    align-items: center;
    margin-bottom: 10px;
}

.about .about-intro hr {
    width: 8%;
    height: 2px;
    margin: 0 auto;
    background-color: #004E42;
    border: none;
}

.about-content {
    width: 100%;
    display: grid;
    grid-template-columns:  1fr 1fr;
    gap: 40px;
    margin-top: 50px;
}

.about-left {
    display: flex;
    flex-direction: column;
}

.about-left p {
    margin-top: 30px;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    text-align: justify;
}

.about-left h3 {
    margin-top: 30px;
    color: #333333;
}

.about-right {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.about-right img {
    width: auto;
    text-align: center;
}

.animated {
    animation: up-down 2s ease-in-out infinite alternate both ;
}


@-webkit-keyframes up-down {
    0% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(-10px);
    }
}

@keyframes up-down {
    0% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(-10px);
    }
    
}

@media only screen and (max-width: 767px) {
    .about {
        display: flex;
        height: 100%;
        padding: 0;
        padding: 0 24px 0 24px !important;
    }

    .about-content {
        display: flex;
        flex-direction: column;
    }

    .about-right img {
        width: 360px;
        height: auto;
        text-align: center;
    }
}
