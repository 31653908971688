.refund-policy {
    display: flex;
    height: 100%;
    padding: 0 3rem 0 3rem;
}

.refund-policy-intro {
    display: flex;
    position: relative;
    flex: 1;
    margin-top: 5rem;
    flex-direction: column;
    margin-left: 24px;
    margin-right: 24px;
}

.refund-policy .refund-policy-intro h1 {
    color: #004E42;
    margin-top: 70px;
    text-align: center !important;
    align-items: center;
    margin-bottom: 10px;
}

.refund-policy .refund-policy-intro hr {
    width: 8%;
    height: 2px;
    margin: 0 auto;
    background-color: #004E42;
    border: none;
}

.refund-policy-par {
    margin-top: 50px;
}

.refund-policy .refund-policy-intro p {
    margin-top: 30px;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    text-align: justify;
}

@media only screen and (max-width: 767px) {
    .refund-policy {
        display: flex;
        height: 100%;
        padding: 0;
    }

    .refund-policy-par {
        margin-top: 10px;
    }
}
