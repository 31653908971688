@import url('https://fonts.cdnfonts.com/css/helvetica-neue-5');

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Helvetica Neue', sans-serif;
    /* scroll-snap-type: y mandatory; */
    scroll-behavior: smooth;    
}

body {
    min-height: 100vh !important;
    background-color: #fff;
}

/* div {
    scroll-snap-align: center;
} */

a {
    text-decoration: none;
    color: inherit;
}

.app {
    width: 80%;
    margin: auto;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media (max-width:750px) {
    .app {
        width: 90%;
    }
}




