#buy_gold {
    display: none;
}

@media screen and (max-width: 768px) {
    #buy_gold {
        display: block;
        border-radius: 50px;
        background: #004E42;
        padding: 9px 14px;
        color: #EBD38C;
        outline: none;
        border: none;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        text-decoration: none;
        font-weight: 600;
        font-size: 14px;
        margin-right: 80px;
        z-index: 999999 !important;
        
    }
}

@media screen and (max-width: 768px) {
    .dfjAjN {
      display: flex;
      flex-direction: column;
      background: rgb(252, 226, 124);
      position: fixed;
      top: 80px;
      width: 100%;
      height: calc(100% - 80px);
      padding: 20px;
      transition: 0.3s ease-in-out;
      transform: translateX(0px);
      z-index: 999999 !important;
    }
}

